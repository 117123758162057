import { Link, useLocation } from '@remix-run/react'
import { LucideMenu } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { aOrganization, aUser, useAtomValue } from '~/atoms'
import { OrganizationLogo } from '~/components'
import { Sheet } from '~/components/ui'
import { cn } from '~/utils'
import AppUserDropdown from './AppUserDropdown'

export default function AppHeader() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const location = useLocation()
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)

  const navigation = useMemo(() => {
    if (organization!.options.singleCompany) {
      return [
        { name: 'Map', to: '/' },
        {
          name: organization!.options.appraisersOnly ? 'Appraisers' : 'Brokers',
          to: '/brokers',
        },
      ]
    }
    return [
      { name: 'Map', to: '/' },
      { name: 'Companies', to: '/companies' },
      {
        name: organization!.options.appraisersOnly ? 'Appraisers' : 'Brokers',
        to: '/brokers',
      },
    ]
  }, [organization])

  useEffect(() => {
    setMobileMenuOpen(false)
  }, [location.pathname])

  if (
    location.pathname.includes('report') ||
    location.search.includes('report')
  ) {
    return null
  }
  return (
    <header
      id="header"
      className="relative z-50 w-full border-b bg-background px-6 print:hidden">
      <nav
        className="flex h-16 items-center justify-between gap-x-6"
        aria-label="App Header">
        <Sheet
          open={mobileMenuOpen}
          onOpenChange={(open) => {
            setMobileMenuOpen(open)
          }}>
          <Sheet.Trigger asChild>
            <button
              type="button"
              className="-m-2.5 p-2.5 text-foreground lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <LucideMenu className="h-6 w-6" aria-hidden="true" />
            </button>
          </Sheet.Trigger>
          <Sheet.Content side="left" className="flex w-64 flex-col py-4">
            <Sheet.Header>
              <OrganizationLogo className="h-8 w-auto" />
            </Sheet.Header>
            <ul role="list" className="-mx-2">
              {navigation.map((item, index) => {
                return (
                  <li
                    key={index}
                    className={cn(
                      'mt-1 rounded-md',
                      location.pathname === item.to && 'bg-muted'
                    )}>
                    <Link
                      to={item.to}
                      className={cn(
                        location.pathname === item.to
                          ? 'bg-muted'
                          : 'hover:bg-muted',
                        'flex gap-x-3 rounded-md p-2 text-base font-normal leading-6'
                      )}>
                      {item.name}
                    </Link>
                  </li>
                )
              })}
              {user ? (
                <li className={cn('mt-1 rounded-md')}>
                  <Link
                    to="/admin"
                    className={cn(
                      location.pathname === '/admin'
                        ? 'bg-muted'
                        : 'hover:bg-muted',
                      'flex gap-x-3 rounded-md p-2 text-base font-normal leading-6'
                    )}>
                    Admin
                  </Link>
                </li>
              ) : (
                <li className={cn('mt-1 rounded-md')}>
                  <Link
                    to="/login"
                    className={cn(
                      location.pathname === '/login'
                        ? 'bg-muted'
                        : 'hover:bg-muted',
                      'flex gap-x-3 rounded-md p-2 text-base font-normal leading-6'
                    )}>
                    Login
                  </Link>
                </li>
              )}
            </ul>
          </Sheet.Content>
        </Sheet>
        <div className="flex lg:flex-1">
          <OrganizationLogo className="h-8 w-auto" />
        </div>
        <div className="hidden lg:flex lg:gap-x-16">
          {navigation.map((item) => (
            <Link
              key={item.name}
              to={item.to}
              className="-mx-3 block rounded-lg px-3 text-base leading-7 text-foreground  hover:underline hover:underline-offset-4">
              {item.name}
            </Link>
          ))}
        </div>

        <div className="flex flex-1 items-center justify-end gap-x-6">
          <AppUserDropdown />
        </div>
      </nav>
      <div className="-mr-4 flex flex-1 justify-end "></div>
    </header>
  )
}
