import { useFetcher } from '@remix-run/react'
import { LucideBell, LucideBellOff } from 'lucide-react'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ValidatedForm,
  useControlField,
  useFormContext,
} from 'remix-validated-form'
import {
  aMapCurrentCount,
  aMapFilters,
  aMapViewState,
  aUser,
  useAtomValue,
} from '~/atoms'
import {
  LoadingCircle,
  HiddenFields,
  TextField,
  HiddenFieldValues,
  FieldContainer,
} from '~/components'
import { Badge, Button, Dialog, Textarea, Toggle } from '~/components/ui'
import { useFormHelpers } from '~/hooks'
import { getDefaultValues, getMapFilterLabels, searchValidator } from '~/models'

function SavedSearchesForm({ onSearchCreate }: { onSearchCreate: () => void }) {
  const user = useAtomValue(aUser)
  const mapFilters = useAtomValue(aMapFilters)
  const currentMapCount = useAtomValue(aMapCurrentCount)
  const mapViewState = useAtomValue(aMapViewState)

  const formId = 'search-form'
  const fetcher = useFetcher()
  const formContext = useFormContext(formId)
  useFormHelpers(formId, formContext)
  const [description, setDescription] = useControlField<string>(
    'description',
    formId
  )
  const [notify, setNotify] = useControlField<boolean>('notify', formId)

  useEffect(() => {
    if (fetcher.state === 'idle' && fetcher.data) {
      onSearchCreate()
    }
  }, [fetcher, onSearchCreate])

  const searchLocation = useMemo(() => {
    if (!mapViewState) {
      return {}
    }
    const ne = Object.values(mapViewState!.bounds.getNorthEast())
    const sw = Object.values(mapViewState!.bounds.getSouthWest())

    return {
      $geoWithin: {
        $geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [sw[0], ne[1]],
              [ne[0], ne[1]],
              [ne[0], sw[1]],
              [sw[0], sw[1]],
              [sw[0], ne[1]],
            ],
          ],
        },
      },
    }
  }, [mapViewState])

  const defaultValues = useMemo(() => {
    return getDefaultValues(user, {
      name: '',
      filters: mapFilters,
      location: searchLocation,
      notify: false,
    })
  }, [mapFilters, searchLocation, user])

  return (
    <ValidatedForm
      id={formId}
      action="/api/actions/create-search"
      method="post"
      fetcher={fetcher}
      validator={searchValidator}
      defaultValues={defaultValues}>
      <HiddenFields slugFields={['name']} />
      <HiddenFieldValues name="filters" value={mapFilters} />
      <HiddenFieldValues name="location" value={searchLocation} />
      <input
        type="hidden"
        name="listingsCount"
        value={currentMapCount?.toString()}
      />
      <input type="hidden" name="lat" value={mapViewState?.lat?.toString()} />
      <input type="hidden" name="lng" value={mapViewState?.lng?.toString()} />
      <input type="hidden" name="z" value={mapViewState?.zoom?.toString()} />

      <div className="flex flex-col gap-6">
        <TextField
          required
          label="Name"
          name="name"
          placeholder="Name"
          disabled={fetcher.state === 'submitting'}
        />
        <FieldContainer label="Description" id="description">
          <Textarea
            id="description"
            name="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={fetcher.state === 'submitting'}
          />
        </FieldContainer>

        {notify && <input type="hidden" name="notify" value="on" />}
        <div className="flex items-start">
          <Toggle
            variant="outline"
            pressed={notify}
            onPressedChange={(pressed) => {
              setNotify(pressed)
            }}>
            {notify ? (
              <LucideBell className="mr-2 h-4 w-4" />
            ) : (
              <LucideBellOff className="mr-2 h-4 w-4" />
            )}
            {notify ? 'Notifications Enabled' : 'Notifications Disabled'}
          </Toggle>
        </div>

        <div className="">
          <p className="mb-2 font-medium">
            Currently Matching {currentMapCount?.toLocaleString()} Listings
          </p>
          <div className="flex flex-wrap gap-2">
            {getMapFilterLabels(mapFilters).map((label) => (
              <Badge key={label} variant="secondary" className="px-1 text-xs">
                {label}
              </Badge>
            ))}
          </div>
        </div>
      </div>
      <Dialog.Footer className="mt-4">
        <Dialog.Close asChild>
          <Button type="button" variant="ghost">
            Cancel
          </Button>
        </Dialog.Close>
        <Button
          type="submit"
          disabled={fetcher.state !== 'idle'}
          onClick={async (e) => {
            e.preventDefault()
            formContext.submit()
          }}>
          {fetcher.state !== 'idle' && (
            <LoadingCircle className="mr-2 h-5 w-5" />
          )}
          {fetcher.state !== 'idle' ? 'Saving' : 'Save'}
        </Button>
      </Dialog.Footer>
    </ValidatedForm>
  )
}

export default function SavedSearchesDialog({
  onSearchCreate,
}: {
  onSearchCreate: () => void
}) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
      }}
      modal={true}>
      <Dialog.Trigger asChild>
        <Button size="sm" variant="default" className="h-8">
          Create
        </Button>
      </Dialog.Trigger>
      <Dialog.Content className="sm:max-w-[425px]">
        <Dialog.Header>
          <Dialog.Title className="font-normal">
            Save Current Search
          </Dialog.Title>
          <Dialog.Description>
            Save the current map view and filters
          </Dialog.Description>
        </Dialog.Header>

        {isOpen && (
          <SavedSearchesForm
            onSearchCreate={() => {
              setIsOpen(false)
              onSearchCreate()
            }}
          />
        )}
      </Dialog.Content>
    </Dialog>
  )
}
