import { Button, Input } from '~/components/ui'
import { cn } from '~/utils'

export default function MapDateFilter({
  className,
  name,
  label,
  filterValue,
  updateFilter,
}: {
  className?: string
  name: string
  label: string
  filterValue?: string
  updateFilter: (key: string, value: any) => void
}) {
  return (
    <div className={cn('space-y-2', className)}>
      <p className="text-sm">
        {label}
        {filterValue && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => {
              updateFilter(name, undefined)
            }}>
            (reset)
          </Button>
        )}
      </p>
      <Input
        type="date"
        value={filterValue || ''}
        onChange={(e) => {
          updateFilter(name, e.target.value || undefined)
        }}
      />
    </div>
  )
}
