import MapboxDraw from '@mapbox/mapbox-gl-draw'
import circle from '@turf/circle'
import turfLength from '@turf/length'

function circleFromTwoVertexLineString(
  geojson: GeoJSON.Feature<GeoJSON.LineString>
) {
  const center = geojson.geometry.coordinates[0]
  const radiusInKm = turfLength(geojson)

  return circle(center, radiusInKm)
}

function getRadiusLabel(geojson: GeoJSON.Feature) {
  const drawnLength = turfLength(geojson) * 1000 // meters
  const feet = drawnLength * 3.28084
  const miles = feet / 5280
  if (miles > 1) {
    return `${Math.round(miles * 10) / 10} mi`
  }
  return `${feet.toFixed(0)} ft`
}

const CircleMode: MapboxDraw.DrawCustomMode<any, any> & {
  clickAnywhere?: (state: any, e: any) => void
} = {
  ...MapboxDraw.modes.draw_line_string,
}

CircleMode.clickAnywhere = function (state, e) {
  if (state.currentVertexPosition === 1) {
    const lineFeature = {
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      properties: {},
      geometry: {
        type: MapboxDraw.constants.geojsonTypes.LINE_STRING,
        coordinates: state.line.coordinates,
      },
    }
    const circleFeature = circleFromTwoVertexLineString(lineFeature)
    const createdFeature = this.newFeature({
      type: MapboxDraw.constants.geojsonTypes.FEATURE,
      properties: {
        isCircle: true,
        center: state.line.coordinates[0],
        radiusInKm: turfLength(lineFeature),
      },
      geometry: circleFeature.geometry,
    })

    // Filtering expects a geometry object with coordinates, not flat coordinates like created by newFeature
    createdFeature.geometry = circleFeature.geometry

    this.addFeature(createdFeature)
    this.map.fire('draw.create', {
      features: [createdFeature],
    })

    return this.changeMode(MapboxDraw.constants.modes.SIMPLE_SELECT, {
      featureIds: [createdFeature.id],
    })
  } else {
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    )
    state.currentVertexPosition += 1
    state.line.updateCoordinate(
      state.currentVertexPosition,
      e.lngLat.lng,
      e.lngLat.lat
    )
  }
}

CircleMode.toDisplayFeatures = function (state, geojson, display) {
  const isActiveLine = geojson.properties.id === state.line.id
  geojson.properties.active = isActiveLine
    ? MapboxDraw.constants.activeStates.ACTIVE
    : MapboxDraw.constants.activeStates.INACTIVE
  if (!isActiveLine) return display(geojson)
  // Only render the line if it has at least one real coordinate
  if (geojson.geometry.coordinates.length < 2) return
  geojson.properties.meta = MapboxDraw.constants.meta.FEATURE
  display(
    MapboxDraw.lib.createVertex(
      state.line.id,
      geojson.geometry.coordinates[geojson.geometry.coordinates.length - 2],
      `${geojson.geometry.coordinates.length - 2}`,
      false
    )
  )
  display(geojson)

  const radius = getRadiusLabel(geojson)
  const currentVertex = {
    type: 'Feature',
    properties: {
      meta: 'currentPosition',
      radius,
      parent: state.line.id,
    },
    geometry: {
      type: 'Point',
      coordinates: geojson.geometry.coordinates[0],
    },
  }
  display(currentVertex)

  const circleFeature = circleFromTwoVertexLineString(geojson)
  circleFeature.properties = { active: 'true' }
  display(circleFeature)

  return null
}

export default CircleMode
