import { Input } from '~/components/ui'

export default function MapSearchFilter({
  search,
  updateFilter,
}: {
  search?: string
  updateFilter: (key: string, value: any) => void
}) {
  return (
    <div className="space-y-2">
      <p className="text-sm">Keyword Search</p>

      <Input
        value={search || ''}
        onChange={(e) => {
          updateFilter('search', e.target.value)
        }}
      />
    </div>
  )
}
