import { Button, SelectMultiple } from '~/components/ui'
import { PROPERTY_TYPES, valuesToValueLabelOptions } from '~/utils'

export default function MapPropertyTypesFilter({
  propertyTypes,
  updateFilter,
}: {
  propertyTypes: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const options = valuesToValueLabelOptions(PROPERTY_TYPES)

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Primary Use
        {propertyTypes.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('propertyTypes', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder="Select Primary Use..."
        options={options}
        selected={valuesToValueLabelOptions(propertyTypes)}
        setSelected={(selected) => {
          updateFilter(
            'propertyTypes',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
