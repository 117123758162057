import { Link, useLocation } from '@remix-run/react'
import { useMemo } from 'react'
import {
  aOrganization,
  aSelectedListing,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { Tabs } from '~/components/ui'

export default function MapNavigationControl() {
  const location = useLocation()
  const organization = useAtomValue(aOrganization)
  const setSelectedListing = useSetAtom(aSelectedListing)

  const currentView = useMemo(() => {
    if (location.pathname.startsWith('/cards')) {
      return 'Cards'
    } else if (location.pathname.startsWith('/layers')) {
      return 'Layers'
    } else if (location.pathname.startsWith('/data')) {
      return 'Data'
    }
    return 'Map'
  }, [location])

  if (
    !organization!.options.listingsEnabled &&
    !organization!.options.dataLayersEnabled
  ) {
    return null
  }

  return (
    <div className="rounded-md shadow-md">
      <Tabs value={currentView}>
        <Tabs.List className="bg-background">
          <Tabs.Trigger
            asChild
            value="Map"
            className="data-[state=active]:bg-primary data-[state=active]:text-white">
            <Link id="nav-map" to={`/${location.search}`}>
              Map
            </Link>
          </Tabs.Trigger>
          {organization!.options.listingsEnabled && (
            <>
              <Tabs.Trigger
                asChild
                value="Cards"
                className="data-[state=active]:bg-primary data-[state=active]:text-white">
                <Link
                  id="nav-cards"
                  to={`/cards${location.search}`}
                  onClick={() => {
                    setSelectedListing(null)
                  }}>
                  Cards
                </Link>
              </Tabs.Trigger>
            </>
          )}
          {organization!.options.dataLayersEnabled && (
            <Tabs.Trigger
              asChild
              value="Layers"
              className="data-[state=active]:bg-primary data-[state=active]:text-white">
              <Link
                id="nav-layers"
                to={`/layers${location.search}`}
                onClick={() => {
                  setSelectedListing(null)
                }}>
                Layers
              </Link>
            </Tabs.Trigger>
          )}
        </Tabs.List>
      </Tabs>
    </div>
  )
}
