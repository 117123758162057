import { Button } from '~/components/ui'

export default function SortSortSelect({
  sort,
  updateFilter,
}: {
  sort?: string
  updateFilter: (key: string, value: any) => void
}) {
  return (
    <div className="space-y-2">
      <p className="text-sm">
        Sort By
        {sort && sort !== 'modifiedD' && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => {
              updateFilter('sort', 'modifiedD')
            }}>
            (reset)
          </Button>
        )}
      </p>
      <select
        className="border-input bg-background ring-offset-background placeholder:text-muted-foreground focus-visible:ring-ring flex h-10 w-full rounded-md border px-3 py-2 text-sm file:border-0 file:bg-transparent file:text-sm file:font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        value={sort || 'modifiedD'}
        onChange={(e) => {
          updateFilter('sort', e.target.value)
        }}>
        <option value="modifiedD">Modified (newest to latest)</option>
        <option value="createdD">Created (newest to latest)</option>
        <option value="offMarketD">Transacted (newest to latest)</option>
        <option value="priceD">Price (highest to lowest)</option>
        <option value="priceA">Price (lowest to highest)</option>
        <option value="rateD">Lease Rate (highest to lowest)</option>
        <option value="rateA">Lease Rate (lowest to highest)</option>
        <option value="availableSfD">Available SF (highest to lowest)</option>
        <option value="availableSfA">Available SF (lowest to highest)</option>
        <option value="acresD">Acres (highest to lowest)</option>
        <option value="acresA">Acres (lowest to highest)</option>
      </select>
    </div>
  )
  // return (
  //   <div className="space-y-2">
  //     <p className="text-sm">Sort By</p>
  //     <Select
  //       value={sort || 'modifiedD'}
  //       onValueChange={(value) => updateFilter('sort', value)}>
  //       <Select.Trigger className="w-full">
  //         <Select.Value placeholder="" />
  //       </Select.Trigger>
  //       <Select.Content>
  //         <Select.Item value="modifiedD">
  //           Modified (newest to latest)
  //         </Select.Item>
  //         <Select.Item value="createdD">Created (newest to latest)</Select.Item>
  //         <Select.Item value="priceD">Price (highest to lowest)</Select.Item>
  //         <Select.Item value="priceA">Price (lowest to highest)</Select.Item>
  //         <Select.Item value="rateD">
  //           Lease Rate (highest to lowest)
  //         </Select.Item>
  //         <Select.Item value="rateA">
  //           Lease Rate (lowest to highest)
  //         </Select.Item>
  //         <Select.Item value="availableSfD">
  //           Available SF (highest to lowest)
  //         </Select.Item>
  //         <Select.Item value="availableSfA">
  //           Available SF (lowest to highest)
  //         </Select.Item>
  //         <Select.Item value="acresD">Acres (highest to lowest)</Select.Item>
  //         <Select.Item value="acresA">Acres (lowest to highest)</Select.Item>
  //       </Select.Content>
  //     </Select>
  //   </div>
  // )
}
