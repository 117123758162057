import { LucideExternalLink } from 'lucide-react'
import { useMemo } from 'react'
import { SelectMultiple, Button } from '~/components/ui'
import { type Company } from '~/models'

export default function MapCompaniesFilter({
  companies,
  filterCompanies,
  updateFilter,
}: {
  companies: Company[]
  filterCompanies?: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const companiesData = useMemo(() => {
    return companies.map((company) => ({
      label: company.name,
      value: company.tid,
      slug: company.slug,
    }))
  }, [companies])

  const selectedCompanies = useMemo(() => {
    if (!filterCompanies) {
      return []
    }
    return companiesData.filter(({ value }) => filterCompanies.includes(value))
  }, [filterCompanies, companiesData])

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Companies
        {(filterCompanies || []).length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('companies', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder={
          companies.length === 0
            ? 'Loading Companies...'
            : 'Search Companies...'
        }
        endAdornment={(company: any) => (
          <a
            href={`/companies/${company.slug}`}
            target="_blank"
            rel="noreferrer">
            <LucideExternalLink className="-m-1 rounded-md p-1.5 text-foreground hover:bg-background" />
          </a>
        )}
        hideEmptySearchResults={true}
        options={companiesData}
        selected={selectedCompanies}
        setSelected={(selected) => {
          updateFilter(
            'companies',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
