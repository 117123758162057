import { withZod } from '@remix-validated-form/with-zod'
import { z } from 'zod'
import { zfd } from 'zod-form-data'
import { type MapFiltersType } from '~/atoms'
import { type ModelOrganization } from './organization'
import { type ModelUser } from './user'
import {
  LocationFilter,
  LocationFilterSchema,
  MapFiltersSchema,
} from './shared'

export type Search = {
  _id: string
  tid: string
  created: string
  modified: string
  organization: ModelOrganization
  user: ModelUser
  slug: string
  name: string
  description?: string

  listingsCount: number
  lat: number
  lng: number
  z: number
  filters: MapFiltersType
  location: LocationFilter

  notify?: boolean | string
}

export const SearchSchema = z.object({
  _id: z.string(),
  tid: z.string(),
  created: z.string(),
  modified: z.string(),
  organization: z.custom<ModelOrganization>(),
  user: z.custom<ModelUser>(),
  name: zfd.text(z.string().min(1, 'Enter a name for this search')),
  slug: zfd.text(z.string()),
  description: zfd.text(z.string().optional()),
  listingsCount: zfd.numeric(z.number()),
  lat: zfd.numeric(z.number()),
  lng: zfd.numeric(z.number()),
  z: zfd.numeric(z.number()),
  filters: MapFiltersSchema,
  location: LocationFilterSchema,

  notify: zfd.checkbox().optional(),
}) satisfies z.ZodType<Search>

export const searchValidator = withZod(SearchSchema)
