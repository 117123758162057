import booleanContains from '@turf/boolean-contains'
import { useEffect, useMemo, useState } from 'react'
import { useListingsQuery } from '~/api'
import {
  aCheckedListings,
  aDrawnFeature,
  aFiltersFind,
  aFiltersSort,
  aMapCurrentCount,
  aMapViewState,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { Listing } from '~/models'

export type MapOutletContextData = {
  listings: Listing[]
  isError: boolean
  isLoading: boolean
  isFetching: boolean
  fetchNextPage: () => void
  hasNextPage: boolean
}

export function useMapProperties() {
  const mapViewState = useAtomValue(aMapViewState)
  const filtersFind = useAtomValue(aFiltersFind)
  const setCurrentMapCount = useSetAtom(aMapCurrentCount)
  const drawnFeature = useAtomValue(aDrawnFeature)
  const setCheckedListings = useSetAtom(aCheckedListings)

  const {
    listings: properties,
    count: totalCount,
    // isError,
    isLoading: isLoadingProperties,
    isFetching: isFetchingProperties,
  } = useListingsQuery({
    key: ['map-properties', 'disable-cache', JSON.stringify(filtersFind)],
    find: {
      ...filtersFind,
    },
    project: {
      slug: 1,
      name: 1,
      'property.address': 1,
      'property.address2': 1,
      location: 1,
      type: 1,
      status: 1,
      propertyTypes: 1,
    },
  })
  const drawnProperties = useMemo(() => {
    if (drawnFeature) {
      return properties.filter((l: any) =>
        booleanContains(drawnFeature, l.location)
      )
    }
    return properties
  }, [properties, drawnFeature])

  // useEffect(() => {
  //   if (drawnFeature) {
  //     setCheckedListings(drawnProperties.map(({ _id }) => _id))
  //   }
  // }, [drawnProperties, drawnFeature, setCheckedListings])

  useEffect(() => {
    if (mapViewState) {
      setTimeout(() => {
        if (!isLoadingProperties)
          setCurrentMapCount(
            drawnProperties.filter((p) =>
              mapViewState!.bounds.contains(
                p.location.coordinates as [number, number]
              )
            ).length
          )
      }, 200)
    }
  }, [mapViewState, drawnProperties, isLoadingProperties, setCurrentMapCount])

  return {
    properties: drawnProperties,
    totalCount,
    isLoadingProperties,
    isFetchingProperties,
  }
}

export function useMapListings() {
  const mapViewState = useAtomValue(aMapViewState)
  const filtersFind = useAtomValue(aFiltersFind)
  const filtersSort = useAtomValue(aFiltersSort)
  const drawnFeature = useAtomValue(aDrawnFeature)
  const [queryEnabled, setQueryEnabled] = useState(false)

  useEffect(() => {
    if (mapViewState) {
      setTimeout(() => {
        setQueryEnabled(true)
      }, 100)
    }
  }, [mapViewState])

  const listingsFind = useMemo(() => {
    if (!mapViewState) {
      return {}
    }
    const find: { [key: string]: any } = {
      ...filtersFind,
    }
    if (drawnFeature) {
      find.location = {
        $geoWithin: {
          $geometry: drawnFeature.geometry,
        },
      }
    } else {
      const sw = Object.values(mapViewState.bounds.getSouthWest())
      const ne = Object.values(mapViewState.bounds.getNorthEast())
      find.location = {
        $geoWithin: {
          $geometry: {
            type: 'Polygon',
            coordinates: [
              [
                [sw[0], ne[1]],
                [ne[0], ne[1]],
                [ne[0], sw[1]],
                [sw[0], sw[1]],
                [sw[0], ne[1]],
              ],
            ],
          },
        },
      }
    }
    return find
  }, [mapViewState?.bounds, filtersFind, drawnFeature])

  const {
    listings,
    isError,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  } = useListingsQuery({
    key: ['map-listings', JSON.stringify({ ...listingsFind, ...filtersSort })],
    find: { status: 'active', ...listingsFind },
    sort: filtersSort,
    project: {
      location: 1,
      created: 1,
      modified: 1,
      name: 1,
      slug: 1,
      property: 1,
      type: 1,
      status: 1,
      propertyTypes: 1,
      filters: 1,
      images: { $slice: 1 },
      company: 1,
      brokers: 1,
    },
    limit: 60, // 120
    enabled: queryEnabled,
  })

  return {
    listings,
    isError,
    isLoading,
    isFetching,
    fetchNextPage,
    hasNextPage,
  }
}
