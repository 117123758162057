import { Link } from '@remix-run/react'
import { LucideLayers } from 'lucide-react'
import { useState } from 'react'
import {
  aOrganization,
  aParcelDataVisible,
  aUser,
  aVisibleLayers,
  useAtomValue,
} from '~/atoms'
import { ControlTooltip, PulseDot } from '~/components'
import { Button, Sheet } from '~/components/ui'
import { useIsEmbed } from '~/hooks'
import {
  DataLayersControl,
  GeographiesControl,
  ParcelDataControl,
} from './layers'

export default function MapLayersSheet() {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const isEmbed = useIsEmbed()
  const [isOpen, setIsOpen] = useState(false)
  const parcelDataVisible = useAtomValue(aParcelDataVisible)
  const visibleLayers = useAtomValue(aVisibleLayers)

  if (
    isEmbed ||
    !user ||
    (!organization!.options.dataLayersEnabled &&
      !organization!.options.parcelDataEnabled)
  ) {
    return null
  }

  return (
    <Sheet
      modal={false}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
      }}>
      <ControlTooltip text="Toggle Layers" side="bottom">
        <Sheet.Trigger asChild>
          <Button variant="map" size="map" className="relative shadow-md">
            <LucideLayers className="h-5 w-5" />
            {(visibleLayers.length > 0 || parcelDataVisible) && (
              <PulseDot className="absolute -right-0.5 -top-0.5" />
            )}
          </Button>
        </Sheet.Trigger>
      </ControlTooltip>
      <Sheet.Content className="flex w-full flex-col" side="right">
        <Sheet.Header>
          <Sheet.Title>Layers</Sheet.Title>
          <Sheet.Description>
            Toggle layers visible to show on map.
            {organization!.options.dataLayersEnabled && (
              <>
                Create custom layers{' '}
                <Link
                  className="text-primary underline-offset-4 hover:underline"
                  to="/admin/data-layers">
                  here
                </Link>
                .
              </>
            )}
          </Sheet.Description>
        </Sheet.Header>
        <div className="-mx-6 flex-1 overflow-auto px-6">
          <ParcelDataControl />
          <GeographiesControl />
          <DataLayersControl />
        </div>
        <div className="flex gap-4 border-t pt-6">
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </div>
      </Sheet.Content>
    </Sheet>
  )
}
