import { useState } from 'react'
import { Marker, Popup } from 'react-map-gl'
import { useDataLayerEntriesQuery } from '~/api'
import {
  aHoverDataLayerEntry,
  aVisibleLayers,
  useAtom,
  useAtomValue,
} from '~/atoms'
import {
  DATA_LAYER_ENTRY_FIELDS,
  DataLayerEntry,
  getDataLayerEntryLabelValues,
} from '~/models'
import { fmtAddress } from '~/utils'

function DataLayer({ dataLayerId }: { dataLayerId: string }) {
  const [visibleEntry, setVisibleEntry] = useState<DataLayerEntry>()
  const [hoverDataLayerEntry, setHoverDataLayerEntry] =
    useAtom(aHoverDataLayerEntry)
  const { dataLayerEntries } = useDataLayerEntriesQuery({
    key: ['data-layer-entries', dataLayerId],
    find: {
      'dataLayer._id': dataLayerId,
    },
  })

  return (
    <>
      {dataLayerEntries.map((entry) => {
        return (
          <Marker
            key={entry._id}
            latitude={entry.property.latitude}
            longitude={entry.property.longitude}
            color="#7c3aed"
            scale={0.75}
            onClick={() => {
              setVisibleEntry(entry)
            }}></Marker>
        )
      })}
      {(visibleEntry || hoverDataLayerEntry) && (
        <Popup
          className="show-close"
          maxWidth="400px"
          key={(visibleEntry || hoverDataLayerEntry)!._id}
          latitude={(visibleEntry || hoverDataLayerEntry)!.property.latitude}
          longitude={(visibleEntry || hoverDataLayerEntry)!.property.longitude}
          closeButton={true}
          closeOnMove={true}
          closeOnClick={false}
          onClose={() => setVisibleEntry(undefined)}
          style={{ zIndex: 100000 }}>
          <p className="mb-1 text-sm font-medium leading-tight">
            {(visibleEntry || hoverDataLayerEntry)!.name}
          </p>
          <p className="mb-1 whitespace-pre-line text-sm">
            {fmtAddress((visibleEntry || hoverDataLayerEntry)!.property)}
          </p>
          <dl className="text-sm">
            {getDataLayerEntryLabelValues(
              (visibleEntry || hoverDataLayerEntry)!,
              DATA_LAYER_ENTRY_FIELDS
            ).map(({ label, value, field }) => {
              let v = value
              if (field?.type === 'number' && field.maskType !== 'year') {
                v = v.toLocaleString()
              }
              if (field?.prefix) {
                v = `${field.prefix}${v}`
              }
              if (field?.suffix) {
                v = `${v}${field.suffix}`
              }
              return (
                <div key={label} className="mb-1 grid grid-cols-3 gap-2">
                  <dt className="col-span-1 font-medium">{label}:</dt>
                  <dd className="col-span-2">{v}</dd>
                </div>
              )
            })}
          </dl>
        </Popup>
      )}
    </>
  )
}

export default function DataLayers() {
  const visibleDataLayers = useAtomValue(aVisibleLayers)

  return (
    <>
      {visibleDataLayers.map((dataLayerId) => (
        <DataLayer key={dataLayerId} dataLayerId={dataLayerId} />
      ))}
    </>
  )
}
