import clsx from 'clsx'
import { LucideMinus } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { useIMask } from 'react-imask'
import { Button, Input } from '~/components/ui'
import { cn } from '~/utils'

type NumberFilterValues = [number | undefined, number | undefined]

function MaskedInput({
  label,
  value,
  updateValue,
  className,
}: {
  label: string
  value: number | undefined
  updateValue: (value: number | undefined) => void
  className?: string
}) {
  const {
    ref,
    value: maskedValue,
    typedValue,
    setTypedValue,
  } = useIMask(
    {
      mask: Number,
      thousandsSeparator: ',',
      scale: 2,
      min: 0,
      radix: '.',
    },
    { defaultTypedValue: value }
  )

  useEffect(() => {
    if (!value) {
      setTypedValue('' as any) // clears 0 initial value
    }
  }, [])

  useEffect(() => {
    updateValue(typedValue === 0 ? undefined : typedValue)
  }, [maskedValue])

  return (
    <Input
      ref={ref as React.Ref<HTMLInputElement>}
      placeholder={label}
      defaultValue={value || undefined}
      className={className}
    />
  )
}

export default function MapMinMaxFilter({
  className,
  name,
  label,
  start,
  end,
  filterValues,
  updateFilter,
}: {
  className?: string
  name: string
  label: string
  start?: React.ReactNode
  end?: React.ReactNode
  filterValues: (number | undefined)[]
  updateFilter: (key: string, value: any) => void
}) {
  const [resetKey, setResetKey] = useState(Date.now())
  const [values, setValues] = useState<NumberFilterValues>(
    filterValues.length > 0
      ? (filterValues as NumberFilterValues)
      : [undefined, undefined]
  )

  const updateValues = (vals: NumberFilterValues) => {
    setValues(vals)
    if (typeof vals[0] === 'undefined' && typeof vals[1] === 'undefined') {
      updateFilter(name, undefined)
    } else {
      updateFilter(name, vals)
    }
  }

  return (
    <div className={cn('space-y-2', className)}>
      <p className="text-sm">
        {label}
        {filterValues.filter((v) => v).length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => {
              updateValues([undefined, undefined])
              setResetKey(Date.now())
            }}>
            (reset)
          </Button>
        )}
      </p>
      <div className="flex items-center justify-stretch">
        <div className="relative">
          {start && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-muted-foreground sm:text-sm">{start}</span>
            </div>
          )}
          <MaskedInput
            key={resetKey}
            label="Min"
            value={values[0]}
            updateValue={(value) => {
              updateValues([value, values[1]])
            }}
            className={clsx(start && 'pl-7', end && 'pr-12')}
          />
          {end && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-0 flex items-center pr-3">
              <span className="text-muted-foreground sm:text-sm">{end}</span>
            </div>
          )}
        </div>
        <div className="mx-2 text-muted-foreground">
          <LucideMinus className="h-3 w-3" />
        </div>
        <div className="relative">
          {start && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-muted-foreground sm:text-sm">{start}</span>
            </div>
          )}
          <MaskedInput
            key={resetKey}
            label="Max"
            value={values[1]}
            updateValue={(value) => {
              updateValues([values[0], value])
            }}
            className={clsx(start && 'pl-7', end && 'pr-12')}
          />
          {end && (
            <div className="pointer-events-none absolute inset-y-0 right-0 z-0 flex items-center pr-3">
              <span className="text-muted-foreground sm:text-sm">{end}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
