import { LucideStar, LucideX } from 'lucide-react'
import { useState } from 'react'
import { type MapRef } from 'react-map-gl'
import { useSearchesQuery } from '~/api'
import { aUser, useAtomValue } from '~/atoms'
import { ControlTooltip, LoadingCircle } from '~/components'
import { Button, Input, Sheet } from '~/components/ui'
import { SavedSearchesDialog, SavedSearchesList } from './searches'

export default function MapSavedSearches({
  mapRef,
}: {
  mapRef: React.RefObject<MapRef>
}) {
  const user = useAtomValue(aUser)
  const [isOpen, setIsOpen] = useState(false)
  const [filter, setFilter] = useState('')
  const searchQueryKey = ['saved-searches', user?._id || '', 'disable-cache']
  const {
    searches,
    isLoading,
    refetch: refetchSearches,
  } = useSearchesQuery({
    key: searchQueryKey,
    find: { 'user._id': user?._id },
    sort: { created: -1 },
    enabled: !!user,
  })

  if (!user) {
    return null
  }

  return (
    <Sheet
      modal={false}
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
      }}>
      <ControlTooltip text="Saved Searches" side="bottom">
        <Sheet.Trigger asChild>
          <Button variant="map" size="map" className="shadow-md">
            <LucideStar className="h-5 w-5" />
          </Button>
        </Sheet.Trigger>
      </ControlTooltip>
      <Sheet.Content className="flex flex-col" side="right">
        <Sheet.Header>
          <div className="item-center -mt-1 flex justify-between pr-6">
            <Sheet.Title className="">Saved Searches</Sheet.Title>
            <SavedSearchesDialog
              key={searches.length}
              onSearchCreate={() => {
                if (!isLoading) {
                  refetchSearches()
                }
              }}
            />
          </div>
          <Sheet.Description>
            Save the current map view and filters, update existing saved
            searches, and subscribe to changes.
          </Sheet.Description>
        </Sheet.Header>
        <div className="-mx-6 flex-1 overflow-auto px-6 pt-1">
          <div className="relative">
            <Input
              placeholder="Filter searches..."
              className="h-9 pr-10"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            {filter.length > 0 && (
              <Button
                size="none"
                variant="ghost"
                onClick={() => setFilter('')}
                className="absolute right-2 top-1/2 -translate-y-1/2 transform !bg-background text-muted-foreground">
                <LucideX className="h-4 w-4" />
              </Button>
            )}
          </div>
          {isLoading && searches.length === 0 ? (
            <div className="mt-8 flex flex-col items-center justify-center">
              <LoadingCircle className="h-6 w-6 text-primary" />
              <span className="mt-2">Loading</span>
            </div>
          ) : (
            <SavedSearchesList
              searchQueryKey={searchQueryKey}
              filter={filter}
              searches={searches}
              mapRef={mapRef}
              setIsOpen={setIsOpen}
            />
          )}
        </div>
        <div className="flex gap-4">
          <Button variant="secondary" onClick={() => setIsOpen(false)}>
            Close
          </Button>
        </div>
      </Sheet.Content>
    </Sheet>
  )
}
