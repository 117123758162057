import { useMemo } from 'react'
import { SelectMultiple } from '~/components/ui'
import { NM_COUNTIES } from '~/utils'

export default function CountyFilter({
  filterCounty,
  updateFilter,
}: {
  filterCounty: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const countyData = useMemo(
    () =>
      NM_COUNTIES.map((county) => {
        return { label: county, value: county }
      }),
    []
  )
  const selectedCounty = useMemo(
    () => countyData.filter(({ value }) => filterCounty.includes(value)),
    [filterCounty, countyData]
  )
  return (
    <div className="space-y-2">
      <p className="text-sm">County</p>
      <SelectMultiple
        placeholder="Select a county..."
        options={countyData}
        selected={selectedCounty}
        setSelected={(selected) => {
          updateFilter(
            'counties',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
