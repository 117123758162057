import { sortBy } from 'lodash-es'
import { useMemo } from 'react'
import { useGeographiesQuery } from '~/api'
import { Button, SelectMultiple } from '~/components/ui'

export default function MapSubmarketsFilter({
  submarkets,
  updateFilter,
}: {
  submarkets: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const { geographies, isLoading } = useGeographiesQuery({
    key: ['map-geographies', 'submarket'],
    find: { type: 'submarket' },
  })

  const submarketOptions = useMemo(() => {
    const submarketOptions: { label: string; value: string }[] = []
    geographies.forEach((geo) => {
      if (
        submarketOptions.findIndex((option) => option.value === geo.name) === -1
      ) {
        submarketOptions.push({
          label: geo.label || geo.name,
          value: geo.name,
        })
      }
    })
    return sortBy(submarketOptions, 'label')
  }, [geographies])

  const selectedSubmarkets = useMemo(
    () => submarketOptions.filter(({ value }) => submarkets.includes(value)),
    [submarkets, submarketOptions]
  )

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Submarkets
        {submarkets.length > 1 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('submarkets', [])}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder={
          isLoading && geographies.length === 0
            ? 'Loading Submarkets...'
            : 'Search Submarkets...'
        }
        options={submarketOptions}
        selected={selectedSubmarkets}
        setSelected={(selected) => {
          updateFilter(
            'submarkets',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
