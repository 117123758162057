import { useLocation, useNavigate, useSearchParams } from '@remix-run/react'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Map, Marker, Popup, type MapRef, type ViewState } from 'react-map-gl'
import {
  aDrawMode,
  aHoverFeature,
  aIsMapEasing,
  aMapCurrentCount,
  aMapFilters,
  aMapStyle,
  aMapViewState,
  aMarkLocation,
  aOrganization,
  aSelectedListing,
  useAtom,
  useAtomValue,
  type SelectedListing,
} from '~/atoms'
import {
  MapView,
  useCurrentMapView,
  useIsSafari,
  useMapCardSizes,
  useMapEaseTo,
} from '~/hooks'
import {
  CLUSTER_ZOOM_LEVEL,
  SERVE_ACCESS_TOKEN,
  STATUSES,
  TYPES,
  tryParseJson,
} from '~/utils'

const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoiY2FtZXJvbi1yZXNpbXBsaWZpIiwiYSI6ImNremc0cHI5aTAyNHgybnBocWV4NHNoY2MifQ.JAWWThmFY0TwUARWnv1ctA'

const MAP_STYLES: { [key: string]: string } = {
  street: 'mapbox://styles/cameron-resimplifi/clkkb5n0y00wg01p22en48v6w', // streets
  satellite: 'mapbox://styles/cameron-resimplifi/cloxae6eu00oh01nses07hz0j',
}

type ListingMapProps = {
  mapRef: React.RefObject<MapRef>
  children: React.ReactNode
}

export default function ListingsMap(props: ListingMapProps) {
  const { mapRef, children } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const organization = useAtomValue(aOrganization)
  const [mapViewState, setMapViewState] = useAtom(aMapViewState)
  const [mapFilters, setMapFilters] = useAtom(aMapFilters)
  const [selectedListing, setSelectedListing] = useAtom(aSelectedListing)
  const currentMapCount = useAtomValue(aMapCurrentCount)
  const mapStyle = useAtomValue(aMapStyle)
  const currentMapView = useCurrentMapView()
  const mapCardSizes = useMapCardSizes()
  const [hoverFeature, setHoverFeature] = useAtom(aHoverFeature)
  const drawMode = useAtomValue(aDrawMode)
  const [markLocation, setMarkLocation] = useAtom(aMarkLocation)
  const isMapEasing = useAtomValue(aIsMapEasing)
  const mapEaseTo = useMapEaseTo(mapRef)
  const isSafari = useIsSafari()

  function updateSearchParams() {
    if (currentMapView === MapView.Map) {
      // const url = new URL(window.location.href)
      const center = mapRef.current?.getCenter()
      const zoom = mapRef.current!.getZoom()
      const bearing = mapRef.current!.getBearing()
      const pitch = mapRef.current!.getPitch()

      setMapFilters({
        ...mapFilters,
        lng: center!.lng.toFixed(6),
        lat: center!.lat.toFixed(6),
        z: zoom.toFixed(3),
        b: isSafari ? undefined : bearing.toFixed(3),
        p: isSafari ? undefined : pitch.toFixed(3),
      })
    }
  }

  useEffect(() => {
    if (mapRef.current) {
      updateSearchParams()
    }
  }, [currentMapView])

  useEffect(() => {
    if (isSafari && mapRef.current && mapRef.current.getBearing() !== 0) {
      mapRef.current.setBearing(0)
      mapRef.current.setPitch(0)
    }
  }, [isSafari, mapRef])

  useEffect(() => {
    document.documentElement.style.overflowY = 'hidden'
    return () => {
      document.documentElement.style.overflowY = 'auto'
    }
  }, [])

  // useEffect(() => {
  //   // TODO Selected listing
  //   if (!mapRef.current) {
  //     return
  //   }
  //   const zoom = mapRef.current!.getZoom()
  //   if (selectedListing) {
  //     mapRef.current!.easeTo({
  //       center: selectedListing!.location.coordinates as LngLatLike,
  //       zoom: zoom > CLUSTER_ZOOM_LEVEL + 1 ? zoom : CLUSTER_ZOOM_LEVEL + 1,
  //       padding: {
  //         left: mapCardSizes.list + mapCardSizes.single,
  //         top: 0,
  //         bottom: 0,
  //         right: 0,
  //       },
  //       bearing: mapRef.current!.getBearing(),
  //       pitch: mapRef.current!.getPitch(),
  //     })
  //   } else {
  //     mapRef.current!.easeTo({
  //       center: mapRef.current!.getCenter(),
  //       zoom,
  //       padding: { left: mapCardSizes.list, top: 0, bottom: 0, right: 0 },
  //       bearing: mapRef.current!.getBearing(),
  //       pitch: mapRef.current!.getPitch(),
  //     })
  //   }
  // }, [selectedListing])

  // useEffect(() => {
  //   if (selectedListing && currentMapCount === 0) {
  //     mapRef.current!.easeTo({
  //       center: mapRef.current!.getCenter(),
  //       zoom: mapRef.current!.getZoom(),
  //       padding: {
  //         left: mapCardSizes.single,
  //         top: 0,
  //         bottom: 0,
  //         right: 0,
  //       },
  //     })
  //   }
  // }, [currentMapCount])

  const initialViewState: ViewState = useMemo(() => {
    let viewState = {
      ...organization!.options.initialViewState,
      padding: { left: mapCardSizes.list, top: 0, right: 0, bottom: 0 },
      bearing: 0,
      pitch: 0,
    }
    if (searchParams.get('lng') && searchParams.get('lat')) {
      viewState = {
        ...viewState,
        latitude: parseFloat(searchParams.get('lat')!),
        longitude: parseFloat(searchParams.get('lng')!),
        zoom: searchParams.get('z')
          ? parseFloat(searchParams.get('z')!)
          : viewState.zoom,
      }
      if (!isSafari) {
        viewState.bearing = searchParams.get('b')
          ? parseFloat(searchParams.get('b')!)
          : viewState.bearing
        viewState.pitch = searchParams.get('p')
          ? parseFloat(searchParams.get('p')!)
          : viewState.bearing
      }
    }
    return viewState
  }, [mapCardSizes, searchParams, organization, isSafari])

  const onClick = useCallback(
    (e: mapboxgl.MapLayerMouseEvent | mapboxgl.MapLayerTouchEvent) => {
      if (currentMapView !== MapView.Map || drawMode !== null) {
        return
      }
      const feature = (e.features || []).find(
        (l) => l.source === 'property-listings'
      )
      if (feature) {
        e.preventDefault()
        if (feature.properties!._id) {
          if (e.originalEvent.metaKey || e.originalEvent.ctrlKey) {
            window.open(`/listings/${feature.properties!.slug}`, '_blank')
          } else {
            navigate(`/${feature.properties!.slug}${location.search}`)
            setSelectedListing({
              ...feature.properties,
              location: {
                // @ts-ignore
                coordinates: feature.geometry.coordinates,
              },
            } as SelectedListing)
          }
        } else {
          mapEaseTo({
            // @ts-ignore
            center: feature.geometry.coordinates,
            zoom:
              mapViewState!.zoom < 8 && feature.properties?.point_count < 10
                ? CLUSTER_ZOOM_LEVEL - 1
                : CLUSTER_ZOOM_LEVEL + 1,
            padding: {
              left: mapCardSizes.list,
              top: 0,
              bottom: 0,
              right: 0,
            },
            duration: 500,
          })
          setSelectedListing(null)
        }
      } else if (location.pathname !== '/') {
        navigate(`/${location.search}`)
        setSelectedListing(null)
      }
    },
    [currentMapView, drawMode, location, mapCardSizes, mapViewState]
  )

  if (!organization!.options.initialViewState) {
    return (
      <div>
        <p>Map view state not set</p>
      </div>
    )
  }

  return (
    <div className="relative h-dvh w-dvw">
      <Map
        ref={mapRef}
        initialViewState={initialViewState}
        mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
        mapStyle={MAP_STYLES[mapStyle]}
        antialias={true}
        minZoom={4}
        maxZoom={18}
        style={{ width: '100%', height: '100%', userSelect: 'none' }}
        customAttribution="Associate™ by Resimplifi"
        interactiveLayerIds={['property-clusters', 'property-single-clusters']}
        dragRotate={!isSafari}
        transformRequest={(url, resourceType) => {
          if (url.includes('serve.')) {
            return {
              url: `${url}?token=${SERVE_ACCESS_TOKEN}`,
            }
          }
          return { url }
        }}
        onMouseMove={(e) => {
          const feature = e.features?.[0]
          if (!mapRef.current) return
          if (
            drawMode === null &&
            feature &&
            (feature.layer.id === 'property-clusters' ||
              feature.layer.id === 'property-single-clusters')
          ) {
            mapRef.current!.getMap().getCanvasContainer().style.cursor =
              'pointer'
          } else {
            mapRef.current!.getMap().getCanvasContainer().style.cursor = 'grab'
          }
        }}
        onMouseDown={() => {
          if (!mapRef.current) return
          mapRef.current!.getMap().getCanvasContainer().style.cursor =
            'grabbing'
        }}
        onMouseUp={() => {
          if (!mapRef.current) return
          mapRef.current!.getMap().getCanvasContainer().style.cursor = 'grab'
        }}
        onLoad={(e) => {
          const { lat, lng } = e.target.getCenter()
          setMapViewState({
            bounds: e.target.getBounds(),
            lat,
            lng,
            zoom: e.target.getZoom(),
          })
        }}
        onMouseEnter={(e) => {
          if (
            drawMode === null &&
            e.features &&
            e.features.length > 0 &&
            // @ts-ignore
            e.features![0].properties.lat
          ) {
            setHoverFeature(e.features![0].properties as any)
          }
        }}
        onMouseOut={() => {
          setHoverFeature(null)
        }}
        onMouseLeave={() => {
          setHoverFeature(null)
        }}
        onClick={(e) => onClick(e)}
        onTouchEnd={(e) => onClick(e)}
        onMoveEnd={(e) => {
          updateSearchParams()
          const { lat, lng } = e.target.getCenter()
          setMapViewState({
            bounds: e.target.getBounds(),
            lat,
            lng,
            zoom: e.target.getZoom(),
          })
          if (!isMapEasing) {
            setMarkLocation(false)
          }
        }}>
        {markLocation && mapViewState && !isMapEasing && (
          <Marker
            latitude={mapViewState?.lat}
            longitude={mapViewState?.lng}
            color="#7c3aed"
          />
        )}
        {hoverFeature && (
          <Popup
            latitude={hoverFeature.lat}
            longitude={hoverFeature.lng}
            style={{ zIndex: 30 }}>
            <p className="mb-1 text-sm font-medium">{hoverFeature.name}</p>
            {hoverFeature.address && (
              <p className="text-sm">{hoverFeature.address}</p>
            )}
            <p className="text-sm">
              {STATUSES[hoverFeature.status]} - {TYPES[hoverFeature.type]} -{' '}
              {tryParseJson(hoverFeature.propertyTypes).join(', ')}
            </p>
          </Popup>
        )}
        {children}
      </Map>
    </div>
  )
}
