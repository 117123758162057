import { useMemo } from 'react'
import { useDataLayersQuery } from '~/api'
import {
  aOrganization,
  aUser,
  aVisibleLayers,
  useAtom,
  useAtomValue,
} from '~/atoms'
import { LoadingCircle } from '~/components'
import { Switch } from '~/components/ui'
import { useIsEmbed } from '~/hooks'
import { UserGroup } from '~/models'

export default function DataLayersControl() {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const isEmbed = useIsEmbed()
  const [visibleLayers, setVisibleLayers] = useAtom(aVisibleLayers)

  const find = useMemo(() => {
    if (!user || user!.group === UserGroup.superAdmin) {
      return {}
    }
    const $or: {}[] = [{ 'user._id': user!._id }]
    if (user!.company) {
      $or.push({ 'company._id': user!.company!._id })
    }
    return { $or }
  }, [user])

  const { dataLayers, isLoading } = useDataLayersQuery({
    key: ['data-layers'],
    enabled:
      !isEmbed && !!user && organization!.options.dataLayersEnabled === true,
    find,
  })

  if (!organization!.options.dataLayersEnabled) {
    return null
  } else if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <LoadingCircle className="mb-2 h-6 w-6 text-primary" /> Loading Layers
      </div>
    )
  } else if (dataLayers.length === 0) {
    return <div className="text-center">No custom data layers created</div>
  }
  return (
    <>
      <p className="text-medium mb-2">Custom Data Layers</p>
      {dataLayers.map((dataLayer) => {
        return (
          <div
            key={dataLayer._id}
            className="mb-4 rounded-sm border p-4 shadow-sm">
            <div className="flex justify-between">
              <p className="overflow-ellipsis text-base">{dataLayer.name}</p>
              <div>
                <Switch
                  id={`layer-${dataLayer.slug}`}
                  checked={visibleLayers.includes(dataLayer._id)}
                  onCheckedChange={(checked) => {
                    if (checked) {
                      setVisibleLayers((prev) => [...prev, dataLayer._id])
                    } else {
                      setVisibleLayers((prev) =>
                        prev.filter((_id) => _id !== dataLayer._id)
                      )
                    }
                  }}
                />
              </div>
            </div>
            {dataLayer.description && (
              <div
                className="prose text-sm"
                dangerouslySetInnerHTML={{ __html: dataLayer.description }}
              />
            )}
          </div>
        )
      })}
    </>
  )
}
