import {
  LucideBell,
  LucideBellOff,
  LucideReplace,
  LucideTrash2,
} from 'lucide-react'
import { useCallback, useMemo, useState } from 'react'
import { type MapRef } from 'react-map-gl'
import { useSearchesQuery } from '~/api'
import {
  aMapCurrentCount,
  aMapFilters,
  aMapViewState,
  useAtomValue,
  useSetAtom,
} from '~/atoms'
import { ControlTooltip, LoadingCircle } from '~/components/common'
import { Badge, Button, toast } from '~/components/ui'
import { useDebounce } from '~/hooks'
import { getMapFilterLabels, type Search } from '~/models'

function SavedSearchRow({
  searchQueryKey,
  search,
  openSearch,
}: {
  searchQueryKey: string[]
  search: Search
  openSearch: (search: Search) => void
}) {
  const { updateSearchData } = useSearchesQuery({ key: searchQueryKey })
  const [isMoreOpen, setIsMoreOpen] = useState(false)
  const [isUpdatingNotify, setIsUpdatingNotify] = useState(false)
  const [isReplacing, setIsReplacing] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const mapFilters = useAtomValue(aMapFilters)
  const currentMapCount = useAtomValue(aMapCurrentCount)
  const mapViewState = useAtomValue(aMapViewState)
  const searchLocation = useMemo(() => {
    if (!mapViewState) {
      return {}
    }
    const ne = Object.values(mapViewState!.bounds.getNorthEast())
    const sw = Object.values(mapViewState!.bounds.getSouthWest())

    return {
      $geoWithin: {
        $geometry: {
          type: 'Polygon',
          coordinates: [
            [
              [sw[0], ne[1]],
              [ne[0], ne[1]],
              [ne[0], sw[1]],
              [sw[0], sw[1]],
              [sw[0], ne[1]],
            ],
          ],
        },
      },
    }
  }, [mapViewState])

  const updateNotify = useCallback(
    async (notify: boolean) => {
      setIsUpdatingNotify(true)
      const response = await fetch('/api/actions/update-search', {
        body: JSON.stringify({ _id: search._id, notify }),
        method: 'POST',
      })
      const result = await response.json()
      if (result.success) {
        updateSearchData((previousSearches) => {
          return previousSearches.map((s) =>
            s._id === search._id ? { ...s, notify } : { ...s }
          )
        })
      } else {
        console.error('Erroring updating notify', result.error)
        toast({
          variant: 'destructive',
          title: 'Error Updating Search',
          description:
            'Please try again. If the problem persists, contact support.',
        })
      }
      setIsUpdatingNotify(false)
    },
    [search, updateSearchData]
  )

  const replaceSearch = useCallback(async () => {
    setIsReplacing(true)
    // <HiddenFieldValues name="filters" value={mapFilters} />
    // <HiddenFieldValues name="location" value={searchLocation} />
    // <input
    //   type="hidden"
    //   name="listingsCount"
    //   value={currentMapCount?.toString()}
    // />
    // <input type="hidden" name="lat" value={mapViewState?.lat?.toString()} />
    // <input type="hidden" name="lng" value={mapViewState?.lng?.toString()} />
    // <input type="hidden" name="z" value={mapViewState?.zoom?.toString()} />

    const data = {
      filters: mapFilters,
      location: searchLocation,
      listingsCount: currentMapCount!,
      lat: mapViewState?.lat,
      lng: mapViewState?.lng,
      z: mapViewState?.zoom,
    }

    const response = await fetch('/api/actions/update-search', {
      body: JSON.stringify({ _id: search._id, data }),
      method: 'PUT',
    })
    const result = await response.json()
    if (result.success) {
      // @ts-ignore
      updateSearchData((previousSearches) => {
        return previousSearches.map((s) =>
          s._id === search._id ? { ...s, ...data } : { ...s }
        )
      })
    } else {
      console.error('Erroring updating notify', result.error)
      toast({
        variant: 'destructive',
        title: 'Error Updating Search',
        description:
          'Please try again. If the problem persists, contact support.',
      })
    }
    setIsReplacing(false)
  }, [
    search,
    mapFilters,
    searchLocation,
    currentMapCount,
    mapViewState,
    updateSearchData,
  ])

  const deleteSearch = useCallback(async () => {
    setIsDeleting(true)
    const response = await fetch('/api/actions/update-search', {
      body: JSON.stringify({ _id: search._id }),
      method: 'DELETE',
    })
    const result = await response.json()
    if (result.success) {
      updateSearchData((previousSearches) => {
        return previousSearches.filter((s) => s._id !== search._id)
      })
    } else {
      console.error('Erroring deleting search', result.error)
      toast({
        variant: 'destructive',
        title: 'Error Deleting Search',
        description:
          'Please try again. If the problem persists, contact support.',
      })
    }
    setIsDeleting(false)
  }, [search, updateSearchData])

  return (
    <div key={search._id} className="rounded-sm border p-4 shadow-sm">
      <div className="flex items-start justify-between gap-2">
        <Button
          variant="link"
          onClick={() => openSearch(search)}
          className="-mt-1 h-auto p-0 text-left">
          <span className="overflow-ellipsis font-medium">{search.name}</span>
        </Button>
        <div className="-mr-1.5 -mt-1.5 flex gap-1">
          <ControlTooltip
            side="bottom"
            text={
              search.notify ? 'Turn off notifications' : 'Turn on notifications'
            }>
            <Button
              size="none"
              variant="ghost"
              className="p-1"
              disabled={isUpdatingNotify}
              onClick={() => updateNotify(!search.notify)}>
              {isUpdatingNotify ? (
                <LoadingCircle className="h-4 w-4 text-primary" />
              ) : search.notify ? (
                <LucideBell className="h-4 w-4" />
              ) : (
                <LucideBellOff className="h-4 w-4" />
              )}
            </Button>
          </ControlTooltip>
          <ControlTooltip
            side="bottom"
            text={
              // search.notify ? 'Turn off notifications' : 'Turn on notifications'
              'Set search to current view'
            }>
            <Button
              size="none"
              variant="ghost"
              className="p-1"
              disabled={isReplacing}
              onClick={() => replaceSearch()}>
              {isReplacing ? (
                <LoadingCircle className="h-4 w-4 text-primary" />
              ) : (
                <LucideReplace className="h-4 w-4 text-primary" />
              )}
            </Button>
          </ControlTooltip>
          <ControlTooltip side="bottom" text={'Delete search'}>
            <Button
              size="none"
              variant="ghost"
              className="p-1"
              disabled={isDeleting}
              onClick={() => deleteSearch()}>
              {isDeleting ? (
                <LoadingCircle className="h-4 w-4 text-destructive" />
              ) : (
                <LucideTrash2 className="h-4 w-4 text-destructive" />
              )}
            </Button>
          </ControlTooltip>
        </div>
      </div>
      {search.description && (
        <p className="mt-1 text-sm">{search.description}</p>
      )}
      <div className="mt-2 flex flex-wrap gap-2">
        {getMapFilterLabels(search.filters).map((label) => (
          <Badge key={label} variant="secondary" className="px-1 text-xs">
            {label}
          </Badge>
        ))}
      </div>
      {/* <p className="mt-2 text-sm italic">{`${search.listingsCount.toLocaleString()} listings checked ${since}`}</p> */}
    </div>
  )
}

export default function SavedSearchesList({
  searchQueryKey,
  filter,
  searches,
  mapRef,
  setIsOpen,
}: {
  searchQueryKey: string[]
  filter: string
  searches: Search[]
  mapRef: React.RefObject<MapRef>
  setIsOpen: (isOpen: boolean) => void
}) {
  const setMapFilters = useSetAtom(aMapFilters)
  const debouncedFilter = useDebounce(filter, 200)

  const filteredSearches = useMemo(() => {
    if (debouncedFilter.length === 0) return searches
    const filterParts = debouncedFilter
      .toLowerCase()
      .split(' ')
      .filter((v) => v)
    return searches.filter((search) => {
      return [
        search.name,
        search.description || '',
        ...Object.keys(search.filters),
      ]
        .map((v) => v.toLowerCase())
        .some((v) => filterParts.some((v2) => v.includes(v2)))
    })
  }, [searches, debouncedFilter])

  const openSearch = useCallback(
    (search: Search) => {
      setIsOpen(false)
      mapRef.current!.flyTo({
        center: [search.lng, search.lat],
        zoom: search.z,
        duration: 500,
      })
      setMapFilters(search.filters)
    },
    [mapRef, setIsOpen, setMapFilters]
  )

  if (searches.length === 0) {
    return (
      <p className="mt-4 italic">
        No saved searches. Create a new one to get started.
      </p>
    )
  }
  return (
    <div className="mt-4 flex flex-col gap-4">
      {filteredSearches.length === 0 && (
        <p className="text-sm italic">No saved searches match your filter.</p>
      )}
      {filteredSearches.map((search) => {
        // const since = formatDistance(new Date(search.modified), new Date(), {
        //   addSuffix: true,
        // })
        return (
          <SavedSearchRow
            key={search._id}
            searchQueryKey={searchQueryKey}
            search={search}
            openSearch={openSearch}
          />
        )
      })}
    </div>
  )
}
