import { Link } from '@remix-run/react'

const navigation = {
  main: [
    { name: 'Privacy Policy', href: '/privacy/' },
    { name: 'Terms of Service', href: '/terms-of-service' },
    { name: 'DMCA', href: '/dmca' },
  ],
}

export default function AppFooter() {
  return (
    <footer id="footer" className="bg-muted/50 print:hidden">
      <div className="mx-auto max-w-7xl overflow-hidden px-6 py-6 sm:py-10 lg:px-8">
        <nav
          className="flex flex-col items-center justify-center space-y-2 sm:flex-row sm:space-x-12 md:space-y-0"
          aria-label="App Footer">
          {navigation.main.map((item) => (
            <div key={item.name}>
              <Link
                to={item.href}
                className="text-muted-foreground hover:text-foreground text-sm leading-6 hover:underline hover:underline-offset-4">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>

        <p className="text-muted-foreground mt-6 text-center text-xs leading-5 sm:mt-10">
          &copy; {new Date().getFullYear()} Resimplifi, Inc. All rights
          reserved.
        </p>
      </div>
    </footer>
  )
}
