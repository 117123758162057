import { aVisibleGeographies, useAtom } from '~/atoms'
import { Switch } from '~/components/ui'

const GEOGRAPHY_LABELS: Record<string, string> = {
  submarket: 'Submarkets',
}

export default function GeographiesControl() {
  const [visibleGeographies, setVisibleGeographies] =
    useAtom(aVisibleGeographies)

  const geographyTypes = ['submarket']

  return (
    <>
      {/* <p className="text-medium mb-2">Geography Features</p> */}
      {geographyTypes.map((geographyType) => {
        return (
          <div
            key={geographyType}
            className="mb-4 rounded-sm border p-4 shadow-sm">
            <div className="flex justify-between">
              <p className="overflow-ellipsis text-base">
                {GEOGRAPHY_LABELS[geographyType]}
              </p>
              <div>
                <Switch
                  id={`layer-${geographyType}`}
                  checked={visibleGeographies.includes(geographyType)}
                  onCheckedChange={(checked) => {
                    setVisibleGeographies((prev) => {
                      if (checked) {
                        return [...prev, geographyType]
                      }
                      return prev.filter((type) => type !== geographyType)
                    })
                  }}
                />
              </div>
            </div>
            <div className="prose text-sm">
              <p>
                Submarkets available for Albuquerque, NM. Contact support if you
                would like to add submarkets for your region.
              </p>
            </div>
          </div>
        )
      })}
    </>
  )
}
