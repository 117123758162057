import { useMemo } from 'react'
import { SelectMultiple } from '~/components/ui'
import { ZIP_CODES } from '~/utils'

export default function PostalCodeFilter({
  filterPostalCode,
  updateFilter,
}: {
  filterPostalCode: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const postalCodeData = useMemo(
    () =>
      ZIP_CODES.map((zone) => {
        const abbr = zone.split(' ')[0]
        return { label: abbr, value: abbr }
      }),
    []
  )
  const selectedPostalCodes = useMemo(
    () =>
      postalCodeData.filter(({ value }) => filterPostalCode.includes(value)),
    [filterPostalCode, postalCodeData]
  )
  return (
    <div className="space-y-2">
      <p className="text-sm">Zip Code</p>
      <SelectMultiple
        placeholder="Select zip code..."
        options={postalCodeData}
        selected={selectedPostalCodes}
        setSelected={(selected) => {
          updateFilter(
            'postalCodes',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
