import { useNavigate } from '@remix-run/react'
import { useSetAtom } from 'jotai'
import { LucidePlusCircle } from 'lucide-react'
import { useState } from 'react'
import { aCheckedListings } from '~/atoms'
import { Button, Command } from '~/components/ui'
import { Report } from '~/models'

export default function ReportAddToExisting({
  reports,
  checkedListings,
}: {
  reports: Report[]
  checkedListings: string[]
}) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const setCheckedListings = useSetAtom(aCheckedListings)

  return (
    <>
      <Button
        variant="ghost"
        size="sm"
        className="w-full justify-start !bg-transparent pl-2"
        onClick={() => setIsOpen(!isOpen)}>
        <LucidePlusCircle className="mr-2 h-4 w-4 text-muted-foreground" />
        Add to Existing
        {/* <div className="flex flex-col items-start">
          Add to Existing
          <span className="-mt-px text-xs italic">Static reports only</span>
        </div> */}
      </Button>
      <Command.Dialog open={isOpen} onOpenChange={setIsOpen}>
        <Command>
          <Command.Input placeholder="Filter static reports..." />
          <Command.Empty>No reports found.</Command.Empty>
          <Command.Group>
            {reports.map((report) => {
              return (
                <Command.Item
                  key={report._id}
                  value={report.name}
                  className="cursor-pointer"
                  onSelect={() => {
                    navigate(`/admin/reports/${report.slug}/edit`, {
                      state: { listingIds: checkedListings },
                    })
                    setIsOpen(false)
                    setCheckedListings([])
                  }}>
                  {report.name}
                </Command.Item>
              )
            })}
          </Command.Group>
        </Command>
      </Command.Dialog>
    </>
  )
}
