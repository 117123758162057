import { useMemo } from 'react'
import { SelectMultiple } from '~/components/ui'
import { ZONES } from '~/utils'

export default function ZoningFilter({
  filterZoning,
  updateFilter,
}: {
  filterZoning: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const zonesData = useMemo(
    () =>
      ZONES.map((zone) => {
        const abbr = zone.split(' ')[0]
        return { label: abbr, value: abbr }
      }),
    []
  )
  const selectedZones = useMemo(
    () => zonesData.filter(({ value }) => filterZoning.includes(value)),
    [filterZoning, zonesData]
  )
  return (
    <div className="space-y-2">
      <p className="text-sm">Zoning</p>
      <SelectMultiple
        placeholder="Select zoning..."
        options={zonesData}
        selected={selectedZones}
        setSelected={(selected) => {
          updateFilter(
            'zoning',
            selected.map(({ value }) => value)
          )
        }}
      />
      {/* <p className="text-sm">
        Companies
        {(filterCompanies || []).length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('companies', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        placeholder={
          companies.length === 0
            ? 'Loading Companies...'
            : 'Search Companies...'
        }
        endAdornment={(company: any) => (
          <a
            href={`/companies/${company.slug}`}
            target="_blank"
            rel="noreferrer">
            <LucideExternalLink className="text-muted-foreground/40 hover:bg-background -m-1 rounded-md p-1.5" />
          </a>
        )}
        hideEmptySearchResults={true}
        options={companiesData}
        selected={selectedCompanies}
        setSelected={(selected) => {
          updateFilter(
            'companies',
            selected.map(({ value }) => value)
          )
        }}
      /> */}
    </div>
  )
}
