import {
  aOrganization,
  aParcelDataResidentialVisible,
  aParcelDataVisible,
  useAtom,
  useAtomValue,
} from '~/atoms'
import { Checkbox, Label, Switch } from '~/components/ui'

export default function ParcelDataControl() {
  const organization = useAtomValue(aOrganization)
  const [parcelDataVisible, setParcelDataVisible] = useAtom(aParcelDataVisible)
  const [parcelDataResidentialVisible, setParcelDataResidentialVisible] =
    useAtom(aParcelDataResidentialVisible)

  if (!organization?.options.parcelDataEnabled) {
    return null
  }
  return (
    <div className="mb-4 rounded-sm border p-4 shadow-sm">
      <div className="flex justify-between">
        <p className="overflow-ellipsis text-base">Parcel Data</p>
        <div>
          <Switch
            id={`layer-parcel-data`}
            checked={parcelDataVisible}
            onCheckedChange={(checked) => {
              setParcelDataVisible(checked)
            }}
          />
        </div>
      </div>
      <p className="prose text-sm">
        <strong>Zoom in</strong> to view parcel data after enabling.
      </p>
      <div className="mt-3 flex items-center gap-2">
        <Checkbox
          id="show-residential-parcels"
          checked={parcelDataResidentialVisible}
          onCheckedChange={(checked) =>
            setParcelDataResidentialVisible(checked.valueOf() === true)
          }
        />
        <Label htmlFor="show-residential-parcels" className="text-sm">
          Show Residential Parcels
        </Label>
      </div>
    </div>
  )
}
